import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";


import "assets/css/main.css";
import "assets/css/modal.css";
import "assets/css/header.css";

import "assets/js/include.js";
import "assets/js/modal.js";


// pages for this kit
import Index from "views/Index.js";
import LoginPage from "views/screens/LoginPage.js";
// import SignUpPage from "views/screens/SignUp.js";
// import ResetPassword from "views/screens/ResetPasswordPage";
// import DashboardPage from "views/screens/DashboardPage";
import MyProfile from "views/screens/myprofile/index.js";
import CheckPermission from "views/screens/CheckPermission.js";

import Dashboard from "views/screens/dashboard/index.js";
// import DashboardStaff from "views/screens/dashboardstaff/index.js";
// import StockStatus from "views/screens/stockstatus/index.js";
// import StockStatusbyExpiry from "views/screens/stockstatusbyexpiry/index.js";
// import ReportSales from "views/screens/reportsales/index.js";
// import ReportProductWiseSales from "views/screens/reportproductwisesales/index.js";
// import ReportReceiveSummary from "views/screens/reportreceivesummary/index.js";
// import ReportProductWiseReceive from "views/screens/reportproductwisereceive/index.js";
// import ReportReceiveReturn from "views/screens/reportreceivereturn/index.js";
// import ReportPaymentStatus from "views/screens/reportpaymentstatus/index.js";
// import ReportAttendanceSheet from "views/screens/reportattendancesheet/index.js";
// import ReportExpenseDetails from "views/screens/reportexpensedetails/index.js";
// import ReportSalesTransaction from "views/screens/reportsalestransaction/index.js";
// import ReportTrendAnalysis from "views/screens/reporttrendanalysis/index.js";


// import ProductGroup from "views/screens/productgroup/index.js";
// import ProductCategory from "views/screens/productcategory/index.js";
// import ProductGeneric from "views/screens/productgeneric/index.js";
// import Strength from "views/screens/strength/index.js";
// import Manufacturer from "views/screens/manufacturer/index.js";
// import Supplier from "views/screens/supplier/index.js";
import Customer from "views/screens/customer/index.js";
import UserEntry from "views/screens/userentry/index.js";
import Team from "views/screens/team/index.js";
import Designation from "views/screens/designation/index.js";
import Department from "views/screens/department/index.js";
import Machine from "views/screens/machine/index.js";
import Machineparts from "views/screens/machineparts/index.js";
import Machinemodel from "views/screens/machinemodel/index.js";
import Machineserial from "views/screens/machineserial/index.js";
import UserRole from "views/screens/userrole/index.js";
import RoleToMenuPermission from "views/screens/roletomenupermission/index.js";
// import Reference from "views/screens/reference/index.js";
// import Client from "views/screens/client/index.js";
// import Branch from "views/screens/branch/index.js";
import AuditLog from "views/screens/auditlog/index.js";
import ErrorLog from "views/screens/errorlog/index.js";

// import Product from "views/screens/product/index.js";
// import Receive from "views/screens/receive/index.js";
// import ReceiveReturn from "views/screens/receivereturn/index.js";
// import Sales from "views/screens/sales/index.js";
// import SalesReturn from "views/screens/salesreturn/index.js";
// import Adjustment from "views/screens/adjustment/index.js";
// import Expense from "views/screens/expense/index.js";
// import Audit from "views/screens/audit/index.js";

import UserContextProvider from './context/user-info-context';

// import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

// const queryClient = new QueryClient()

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

let userInfo = null;

userInfo = {
  FacilityId: 0,
  FacilityName: 'NA',
  LangId: 'en_GB'
};

ReactDOM.render(
  <UserContextProvider userInfo={userInfo}>
    {/* <QueryClientProvider client={queryClient}> */}
      <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
        <Suspense>
          <Switch>

            <Route path="/home" render={(props) => <Index {...props} />} />
            <Route path="/login" render={(props) => <LoginPage {...props} />} />
            {/* <Route path="/signup" render={(props) => <SignUpPage {...props} />} /> */}
            {/* <Route path="/reset-password" render={(props) => <ResetPassword {...props} />} /> */}
            {/* <Route path="/dashboard" render={(props) => <DashboardPage {...props} />} /> */}
      			<Route path="/myprofileweb" render={(props) => <MyProfile {...props} />} />
            <Route path="/check-permission" render={(props) => <CheckPermission {...props} />} />

            <Route path="/dashboard" render={(props) => <Dashboard {...props} />} />
            {/* <Route path="/dashboardstaff" render={(props) => <DashboardStaff {...props} />} /> */}
            {/* <Route path="/stockstatus" render={(props) => <StockStatus {...props} />} />
            <Route path="/stockstatusbyexpiry" render={(props) => <StockStatusbyExpiry {...props} />} />
            <Route path="/reportsales" render={(props) => <ReportSales {...props} />} />
            <Route path="/reportproductwisesales" render={(props) => <ReportProductWiseSales {...props} />} />
            <Route path="/reportreceivesummary" render={(props) => <ReportReceiveSummary {...props} />} />
            <Route path="/reportproductwisereceive" render={(props) => <ReportProductWiseReceive {...props} />} />
            <Route path="/reportreceivereturn" render={(props) => <ReportReceiveReturn {...props} />} />
            <Route path="/reportpaymentstatus" render={(props) => <ReportPaymentStatus {...props} />} />
            <Route path="/reportattendancesheet" render={(props) => <ReportAttendanceSheet {...props} />} />
            <Route path="/reportexpensedetails" render={(props) => <ReportExpenseDetails {...props} />} />
            <Route path="/reportsalestransaction" render={(props) => <ReportSalesTransaction {...props} />} />
            <Route path="/reporttrendanalysis" render={(props) => <ReportTrendAnalysis {...props} />} /> */}

            {/* <Route path="/productgroup" render={(props) => <ProductGroup {...props} />} /> */}
            {/* <Route path="/productcategory" render={(props) => <ProductCategory {...props} />} /> */}
            {/* <Route path="/productgeneric" render={(props) => <ProductGeneric {...props} />} /> */}
            {/* <Route path="/strength" render={(props) => <Strength {...props} />} /> */}
            {/* <Route path="/manufacturer" render={(props) => <Manufacturer {...props} />} /> */}
            {/* <Route path="/supplier" render={(props) => <Supplier {...props} />} /> */}
            <Route path="/customer" render={(props) => <Customer {...props} />} />
            <Route path="/userentry" render={(props) => <UserEntry {...props} />} />
            <Route path="/team" render={(props) => <Team {...props} />} />
            <Route path="/designation" render={(props) => <Designation {...props} />} />
            <Route path="/department" render={(props) => <Department {...props} />} />
            <Route path="/machine" render={(props) => <Machine {...props} />} />
            <Route path="/machineparts" render={(props) => <Machineparts {...props} />} />
            <Route path="/machinemodel" render={(props) => <Machinemodel {...props} />} />
            <Route path="/machineserial" render={(props) => <Machineserial {...props} />} />
            <Route path="/userrole" render={(props) => <UserRole {...props} />} />
            <Route path="/roletomenupermission" render={(props) => <RoleToMenuPermission {...props} />} />
            {/* <Route path="/reference" render={(props) => <Reference {...props} />} /> */}
            {/* <Route path="/client" render={(props) => <Client {...props} />} />
            <Route path="/branch" render={(props) => <Branch {...props} />} /> */}
            <Route path="/auditlog" render={(props) => <AuditLog {...props} />} />
            <Route path="/errorlog" render={(props) => <ErrorLog {...props} />} />

            {/* <Route path="/product" render={(props) => <Product {...props} />} />
            <Route path="/receive" render={(props) => <Receive {...props} />} />
            <Route path="/receivereturn" render={(props) => <ReceiveReturn {...props} />} />
            <Route path="/sales" render={(props) => <Sales {...props} />} />
            <Route path="/salesreturn" render={(props) => <SalesReturn {...props} />} />
            <Route path="/adjustment" render={(props) => <Adjustment {...props} />} /> */}
            {/* <Route path="/expense" render={(props) => <Expense {...props} />} /> */}
            {/* <Route path="/audit" render={(props) => <Audit {...props} />} /> */}


            <Route path="/" render={(props) => <Index {...props} />} />

          </Switch>
        </Suspense>
      </BrowserRouter>
    {/* </QueryClientProvider> */}
  </UserContextProvider>,
  document.getElementById("root")
);
